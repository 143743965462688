import { Stack } from '@mui/material'
import PropTypes from 'prop-types'

import { getStaticPaths } from '@/features/i18n/server'

import { AppLayout } from '@/features/layout'
import {
  HomeBannerToServices,
  HomeCTA,
  HomeHero,
  HomeServices,
  HomeTestimonials,
  HomeWho,
} from '@/features/pages/home'

import {
  depoimentoContentService,
  servicoContentService,
} from '@/features/content'
import { buildStaticProps } from '@/features/pages/server'

export async function getStaticProps({ params }) {
  const propsWrapper = await buildStaticProps(params, 'home')

  const locale = params?.locale || 'pt'

  // Aqui nós pegamos todos os depoimentos e serviços e extraimos apenas os campos que precisamos
  // para a página inicial. Dessa forma, não precisamos do body completo de cada depoimento e serviço
  const allDepoimentos = depoimentoContentService.getAllDepoimentos(locale)
  const depoimentos = allDepoimentos.map(item => ({
    url: item.url,
    locale: item.locale,
    slug: item.slug,
    name: item.name,
    testimonial: item.testimonial,
    position: item.position,
    image: item.image || null,
  }))

  const allServicos = servicoContentService.getAllServicos(locale)
  const servicos = allServicos.map(item => ({
    id: item.id,
    url: item.url,
    locale: item.locale,
    slug: item.slug,
    title: item.title,
    description: item.description,
    icon: item.icon,
  }))

  propsWrapper.props.depoimentos = depoimentos
  propsWrapper.props.servicos = servicos

  return propsWrapper
}

export { getStaticPaths }

HomePage.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    })
  }).isRequired,
  depoimentos: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      testimonial: PropTypes.string.isRequired,
      position: PropTypes.string,
    })
  ).isRequired,
  servicos: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default function HomePage({ page, depoimentos, servicos }) {
  return (
    <AppLayout
      title={page.title}
      description={page.description}
      image={page.image}
    >
      <Stack gap={8}>
        <HomeHero />
        <HomeWho />
        <HomeBannerToServices />
        <HomeServices servicos={servicos} />
        <HomeTestimonials depoimentos={depoimentos} />
        <HomeCTA />
      </Stack>
    </AppLayout>
  )
}
