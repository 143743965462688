import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import { Box, Button, Container } from '@mui/material'

export default function HomeHero() {

  return (
    <Box
      component="section"
      id="homeHero"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        position: 'relative',
      }}
    >
      <Box
        component="video"
        autoPlay
        muted
        loop
        id="heroVideo"
        poster="/videos/home/hero.jpg"
        sx={{
          position: 'absolute',
          zIndex: '-1',
          width: '100vw',
          height: '100vh',
          objectFit: 'cover',
          overflow: 'hidden',
          // filter: 'brightness(0.3)',
          backgroundColor: 'grey.darker',
        }}
      >
        <source src="/videos/home/hero-optimized.mp4" type="video/mp4" />
      </Box>

      <Container sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        width: '100vw',
          height: '100vh',
        alignItems: 'center',
      }}>

        <Button
          color="light"
          onClick={() => {
            const homeIntro = document.getElementById('homeWho')
            if (homeIntro) {
              homeIntro.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
          }}
        >
          <KeyboardArrowDownOutlinedIcon
            sx={{
              fontSize: '6rem',
              color: 'rgba(255, 255, 255, 0.5)',
            }}
          />
        </Button>
      </Container>
    </Box>
  )
}
